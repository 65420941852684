<template>
  <ServerSpecs
    role="Database"
    :cores="16"
    :ram="32"
    :data-disk="`${ size_of_notifs_retention_period_tb.toFixed(1) }-TB (${ size_of_notifs_per_day_gb.toFixed(0) }-GB per day)`">
      Transaction Log partition: 200GB (high-speed SSD)<br/>
      SQL Server 2016<br/>
      <div class="block mt-6">
        <h2 class="subtitle is-5">Adjustments</h2>
        <b-field label="Hours in working day">
          <b-numberinput v-model="dailyComputerUsage" placeholder="1" :min="1" :max="24"/>
        </b-field>
        <b-field label="Days in working week">
          <b-numberinput v-model="daysPerWeek" placeholder="1" :min="1" :max="7"/>
        </b-field>
        <b-field label="# of alerts per 'Baseline' user, per hour">
          <b-numberinput v-model="baselineNotificationsPerHour" placeholder="1" :min="1" :max="200"/>
        </b-field>
        <b-field label="# of alerts per 'Targeted' user, per hour">
          <b-numberinput v-model="targetedNotificationsPerHour" placeholder="1" :min="1" :max="5000"/>
        </b-field>
        <b-field label="% of users on shift at any given time">
          <b-numberinput v-model="ratioOfUsersOnShiftAtAnyGivenTime" placeholder="1" :min="1" :max="100"/>
        </b-field>
      </div>
  </ServerSpecs>
</template>

<script>
import ServerSpecs from './ServerSpecs.vue'

const sizeOfNotificationKB = 3
const sizeOfScreenshotKB = 40
const ratioBaselineUsers = 0.9 // 90%
const ratioTargetedUsers = 1.0 - ratioBaselineUsers
const targetNotifsWithoutScreenshots = 0.1
const targetNotifsWithScreenshots = (1.0 - targetNotifsWithoutScreenshots)
const notifsWithoutScreenshots = 0.55
const notifsWithScreenshots = (1.0 - notifsWithoutScreenshots)

export default {
  components: { ServerSpecs },
  props: {
    survey: Object
  },
  data () {
    return {
      dailyComputerUsage: 8,
      daysPerWeek: 5,
      baselineNotificationsPerHour: 10,
      targetedNotificationsPerHour: 200,
      addContingency: 0.2,
      ratioOfUsersOnShiftAtAnyGivenTime: 30 // %
    }
  },
  computed: {
    baseline_user_count () {
      return Math.round(this.survey.num_of_desktop_users * ratioBaselineUsers)
    },
    target_user_count () {
      return Math.round(this.survey.num_of_desktop_users * ratioTargetedUsers)
    },
    baseline_notifications_per_day () {
      return this.baseline_user_count
        * this.baselineNotificationsPerHour
        * this.dailyComputerUsage
        * (this.ratioOfUsersOnShiftAtAnyGivenTime / 100.0)
    },
    target_notifications_per_day () {
      return this.target_user_count
        * this.targetedNotificationsPerHour
        * this.dailyComputerUsage
        * (this.ratioOfUsersOnShiftAtAnyGivenTime / 100.0)
    },
    baseline_notifications_per_day_without_screenshots () {
      return this.baseline_notifications_per_day * notifsWithoutScreenshots
    },
    baseline_notifications_per_day_with_screenshots () {
      return this.baseline_notifications_per_day * notifsWithScreenshots
    },
    target_notifications_per_day_without_screenshots () {
      return this.target_notifications_per_day * targetNotifsWithoutScreenshots
    },
    target_notifications_per_day_with_screenshots () {
      return this.target_notifications_per_day * targetNotifsWithScreenshots
    },
    size_of_baseline_notifications_per_day_kb () {
      return (this.baseline_notifications_per_day_without_screenshots * sizeOfNotificationKB)
        + (this.baseline_notifications_per_day_with_screenshots * sizeOfScreenshotKB)
    },
    size_of_target_notifications_per_day_kb () {
      return (this.target_notifications_per_day_without_screenshots * sizeOfNotificationKB)
        + (this.target_notifications_per_day_with_screenshots * sizeOfScreenshotKB)
    },
    size_of_notifs_kb () {
      return this.size_of_baseline_notifications_per_day_kb + this.size_of_target_notifications_per_day_kb
    },
    size_of_notifs_per_day_gb () {
      return this.size_of_notifs_kb / 1024.0 / 1024.0
    },
    size_of_notifs_per_week_gb () {
      return this.size_of_notifs_per_day_gb * this.daysPerWeek
    },
    size_of_notifs_month_gb () {
      return this.size_of_notifs_per_week_gb * 4
    },
    size_of_notifs_retention_period_tb () {
      return ((this.size_of_notifs_month_gb * this.survey.retention_in_months) * (1.0 + this.addContingency)) / 1024.0
    }
  }
}
</script>