<template>
  <div class="has-text-centered">
    <div class="my-5">
      <div class="has-text-link">
      <font-awesome-icon icon="desktop" class="fa-6x"/>
      <h2 class="title is-4">{{ role || 'Server' }}</h2>
      </div>
      <b-tag type="is-link" size="is-medium" rounded>Windows Server 2019</b-tag><br/>
      <p class="mt-3">
        {{ cores || 8 }}-Core Xeon (or equivalent)<br/>
        Minimum {{ ram || 8 }}-GB RAM<br/>
        .NET Framework<br/>
        OS partition: 200GB<br/>
        Data partition: {{ dataDisk || '200GB' }} (RAID 5)<br/>
        <slot></slot>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    role: String,
    cores: Number,
    ram: Number,
    dataDisk: String,
  }
}
</script>