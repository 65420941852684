<template>
  <div class="mobile">
    <h1 class="title">Mobile Requirement</h1>
    <h2 class="subtitle">Provide details about the customer's mobile environment</h2>
    <div class="block container is-max-desktop">
      
      <section class="section pt-6">
        <b-field label="How many mobile licenses will be needed?">
          <b-numberinput v-model="survey.num_of_mobile_licenses_needed" placeholder="100" :min="100"/>
        </b-field>
      </section>

      <section class="section pt-2">
      <b-field label="Usage policy">
        <b-select v-model="survey.mobile_device_policy" placeholder="Select a policy">
          <option value="cobo">Corporate Owned/Business Only (COBO)</option>
          <option value="cope">Corporate Owned/Personally Enabled (COPE)</option>
          <option value="byod">Bring Your own Device (BYoD)</option>
        </b-select>
      </b-field>
      </section>

      <section class="section pt-2">
        <b-field message="ViPro currently only supports Android OS">
          <b-checkbox v-model="survey.confirm_android" size="is-large">
            Please confirm all devices are Android
          </b-checkbox>
        </b-field>
      </section>

      <section class="section pt-2">
        <h2 class="subtitle">Android Versions</h2>
        <b-field label="Earliest version needing monitoring" message="please note Android 12 is not yet supported">
          <b-select v-model="survey.android_min_version" placeholder="Select earliest version">\
            <option>Kitkat</option>
            <option>Lollipop</option>
            <option>Marshmellow</option>
            <option>Nougat 7.0</option>
            <option>Nougat 7.1</option>
            <option>Oreo 8.0</option>
            <option>Oreo 8.1</option>
            <option>Pie</option>
            <option>Android 10</option>
            <option>Android 11</option>
          </b-select>
        </b-field>
      </section>

      <section class="section pt-2">
        <h2 class="subtitle">Features</h2>
        <p class="content">Select which features you want ViPro Mobile to capture:</p>
        <b-field>
          <b-checkbox v-model="survey.uses_im_whatsapp">WhatsApp</b-checkbox>
        </b-field>
        <b-field>
          <b-checkbox v-model="survey.uses_im_wechat">WeChat</b-checkbox>
        </b-field>
        <b-field>
          <b-checkbox v-model="survey.uses_mobile_enterprise_mode">All user activity (Enterprise mode)</b-checkbox>
        </b-field>
      </section>

      <section class="section pt-2">
      <b-field label="Manufacturer selection process">
        <b-select v-model="survey.mobile_device_manufacturer" placeholder="Select a process">
          <option value="samsung">Generally use Samsung</option>
          <option value="google">Generally use Google</option>
          <option value="blackberry">Generally use Blackberry Android</option>
          <option value="micrsoft">Generally use Microsoft Android</option>
          <option value="mix">Mixture of all brands</option>
        </b-select>
      </b-field>
      </section>

      <section class="section pt-2">
        <div v-if="survey.mobile_browser_used && survey.mobile_browser_used !== 'chrome'" class="notification is-warning">
          It is unlikely at this time that ViPro will be able to capture mobile web browsing history.
        </div>
        <b-field label="Web Browser">
          <b-select v-model="survey.mobile_browser_used" placeholder="Select a browser">
            <option value="samsung">Samsung's Internet browser</option>
            <option value="chrome">Google Chrome</option>
            <option value="other">Another browser not listed here</option>
            <option value="mix">Users can choose any browser they wish</option>
          </b-select>
        </b-field>
      </section>

      <section class="section pt-2">
        <b-field label="Deployment strategy">
          <b-select v-model="survey.mobile_deployments_via" placeholder="Select a strategy">
            <option value="mdm">MDM, EMM or UEM</option>
            <option value="side-loading">Manual / Side-loading</option>
          </b-select>
        </b-field>
      
        <div v-if="survey.mobile_deployments_via === 'mdm'" class="pt-2">
          <b-field label="MDM Used">
            <b-select v-model="survey.mobile_mdm_used" placeholder="Select an MDM">
              <option value="play">Google Play (private store)</option>
              <option value="bes">Blackberry BES12</option>
              <option value="mobileiron">Mobile Iron</option>
              <option value="msintune">Microsoft Intune</option>
              <option value="ibm_maas360">IBM MaaS360</option>
              <option value="vmware">VMWare AirWatch / Workspace ONE</option>
            </b-select>
          </b-field>
        </div>
      </section>

      <section class="section pt-2">
        <div v-if="survey.use_android_profile" class="notification is-warning">
          ViPro must be installed on the Personal profile to avoid a long-standing
          Android OS bug. A discussion needs to be had with the customer around the
          possible capture of user's personal activity.
        </div>
        <b-field>
          <b-checkbox v-model="survey.use_android_profile" size="is-large">
            Devices have Work and Personal profiles enabled?
            <a target="_blank" href="https://blog.google/products/android-enterprise/work-profile-new-standard-employee-privacy/">(huh?)</a>
          </b-checkbox>
        </b-field>
        <b-field>
          <b-checkbox v-model="survey.use_android_kiosk" size="is-large">
            Android 'kiosk mode' enabled or is the Home/Launcher restricted?
          </b-checkbox>
        </b-field>
        <b-field>
          <b-checkbox v-model="survey.use_android_hardening" size="is-large">
            Devices are security hardened or UI restricted?
          </b-checkbox>
        </b-field>
        <b-field>
          <b-checkbox v-model="survey.use_android_tp_security_services" size="is-large">
            Devices have anti-virus or other third-party security services running?
          </b-checkbox>
        </b-field>
        <b-field v-if="survey.use_android_tp_security_services">
            <b-input type="textarea"
                minlength="10"
                maxlength="1000"
                v-model="survey.android_tp_services_used"
                placeholder="Describe the security services installed here, ideally with product names...">
            </b-input>
        </b-field>
        <div v-if="survey.use_android_battery_optimiser" class="notification is-warning">
          Whilst ViPro doesn't use significant amounts of battery, some third-party
          battery optimisers affect <em>all</em> running services and are likely to
          interferre with ViPro's monitoring. It is recommended the customer adds
          ViPro to the optimiser's whitelist (assuming this functionality is available).
        </div>
        <b-field>
          <b-checkbox v-model="survey.use_android_battery_optimiser" size="is-large">
            Devices have battery optimiser software running?
          </b-checkbox>
        </b-field>
        <b-field message="ViPro cannot be supported when beta/early preview software is used as it cannot be tested to ensure no conflicts exist.">
          <b-checkbox v-model="survey.confirmed_android_no_beta" size="is-large">
            Confirm beta/preview software <strong>is not</strong> being used on these devices
          </b-checkbox>
        </b-field>
      </section>

      <section v-if="survey.hosting_strategy !== 'cloud'" class="section pt-2">
      <b-field label="Networking to on-premise">
        <b-select v-model="survey.networking_to_onprem" placeholder="Select a method">
          <option value="vpn">Routing achieved via VPN</option>
          <option value="proxy">Routing achieved via Proxy</option>
          <option value="public_ip">Service will have a public IP address (public Internet)</option>
        </b-select>
      </b-field>
      </section>

      <section class="section pt-2">
        <div v-if="survey.mobile_onboarding_process === 'by_user' && survey.use_android_kiosk === true" class="notification is-danger">
          If the devices are restricted (e.g. kiosk mode), then the user will not have
          the necessary permissions to on-board this themselves. Please discuss with the
          customer.
        </div>
        <b-field label="On-boarding process" message="all newly installed apps are held in quarantine by the OS until a user opens them; therefore, a simple on-boarding process is required for ViPro to activate.">
          <b-select v-model="survey.mobile_onboarding_process" placeholder="Select a process">
            <option value="by_admin">Devices will be on-boarded before given to the end-user</option>
            <option value="by_user">End-users will be instructed to on-board device themselves</option>
          </b-select>
        </b-field>
      </section>

      <section class="section pt-2">
      <b-field label="IMEIs for PoC">
        <b-input type="textarea"
          minlength="10"
          maxlength="300"
          v-model="survey.mobile_imei_demo_phones"
          placeholder="If the customer has 1-3 devices they wish to use for a PoC, please provide their IMEI numbers here">
        </b-input>
      </b-field>
      </section>

      <section class="section pt-2">
      <b-field label="Notes and Ad-hoc Requests">
        <b-input type="textarea"
          minlength="10"
          maxlength="10000"
          v-model="survey.mobile_other_requirements"
          placeholder="Any other ad-hoc or specific requirements needed that aren't captured on this page already?">
        </b-input>
      </b-field>
      </section>
      
    </div>
    <div class="block">
      <wizard-steps :invalid="invalid"
        @next="$emit('next')"
        @previous="$emit('previous')"
        @save="$emit('save', survey)">
        <span v-if="error" class="has-text-danger">*{{ error }}</span>
      </wizard-steps>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['next', 'previous'],
  props: {
    survey: Object,
    lastDirectionWasForwards: Boolean
  },
  mounted () {
    // only needed if need mobile component license
    if (this.survey.use_mobile !== true) {
      this.$emit(this.lastDirectionWasForwards ? 'next' : 'previous')
      return
    }
  },
  computed: {
    error () {
      if (!(this.survey.num_of_mobile_licenses_needed > 0)) return 'number of licenses needed'
      if (!this.survey.mobile_device_policy) return 'choose a device usage policy'
      if (!this.survey.confirm_android) return 'confirm Android expectation'
      if (!this.survey.android_min_version) return 'confirm earliest version of Android'
      if (!this.survey.mobile_device_manufacturer) return 'choose a manufacturer selection process'
      if (!this.survey.mobile_deployments_via) return 'choose a deployment strategy'
      if (this.survey.mobile_deployments_via === 'mdm' && !this.survey.mobile_mdm_used) return 'choose an MDM'
      if (!this.survey.confirmed_android_no_beta) return 'you must confirm no beta/preview software is being used'
      if (this.survey.hosting_strategy !== 'cloud' && !this.survey.networking_to_onprem) return 'network routing method must be supplied'
      if (!this.survey.mobile_onboarding_process) return 'choose an on-boarding process'
      return null
    },
    invalid () {
      return this.error !== null
    }
  }
}
</script>