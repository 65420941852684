<template>
  <div class="done">
    <h1 class="title">Readiness Captured</h1>
    <h2 class="subtitle">Thank you for collecting this information</h2>
    <div class="block container is-max-desktop">

      <div v-if="is_edit" class="block">
        <h2 class="subtitle">Survey Share Link</h2>
        Please let Vigilant know when you are happy this survey is ready for them to review.<br/>
        The URI for this survey is:
        <pre>{{ uri }}</pre>
        <b-button @click="copyURI" type="is-primary">Copy to Clipboard</b-button>
      </div>

      <div v-if="survey.hosting_strategy !== 'cloud'" class="block">
        <b-collapse
          aria-id="contentIdForA11y2"
          class="panel"
          animation="slide"
          :open="true">
          <template #trigger>
            <div
                class="panel-heading"
                role="button"
                aria-controls="contentIdForA11y2">
                <strong>On-Premise Server Requirements</strong>
            </div>
          </template>
          <div class="content">
            <div class="columns has-text-centered">
              <div class="column"><FrontendServerSpec /></div>
              <div class="column"><BackendServerSpec :survey="survey" /></div>
            </div>
          </div>
      </b-collapse>
      </div>
      
      <div class="block">
        <hr/>
        <h2 class="subtitle">Configuration Dump</h2>
        <p class="content">Alternatively, please provide the following data to Vigilant in a .json or .txt file</p>
        <b-button @click="copyJSON" type="is-primary">Copy to Clipboard</b-button>
        <pre>{{ surveyJson }}</pre>
      </div>
    </div>
    <div class="block">
      <wizard-steps disable-next 
        @previous="$emit('previous')"
        @save="$emit('save', survey)"
        />
    </div>
  </div>
</template>

<script>
import FrontendServerSpec from '../reusable/FrontendServerSpec.vue'
import BackendServerSpec from '../reusable/BackendServerSpec.vue'

export default {
  emits: ['previous'],
  props: {
    survey: Object
  },
  components: { FrontendServerSpec, BackendServerSpec },
  computed: {
    is_edit () {
      return this.$route.params.id != null
    },
    surveyJson () {
      return JSON.stringify(this.survey, {}, 2)
    },
    uri () {
      return document.location.href
    }
  },
  methods: {
    copyURI () {
      this.copy(this.uri, 'uri')
    },
    copyJSON () {
      this.copy(this.surveyJson, 'configuration')
    },
    copy (val, resource) {
      navigator.clipboard.writeText(val)
      this.$buefy.snackbar.open({
        message: `${resource} copied to clipboard`,
        type: 'is-success',
        position: 'is-top',
      })
    }
  }
}
</script>