<template>
  <div class="desktop">
    <h1 class="title">Desktop Requirement</h1>
    <h2 class="subtitle">Provide details about the customer's office environment</h2>
    <div class="block container is-max-desktop">

      <section class="section pt-6">
        <b-field label="How many desktop users are there?" message="ViPro Desktop Agent is licensed by the number of users, not computers/terminals.">
          <b-numberinput v-model="survey.num_of_desktop_users" placeholder="100" :min="100"/>
        </b-field>
      </section>

      <section class="section pt-2">
        <h2 class="subtitle">Purpose for desktop capture</h2>
        <b-field>
          <b-checkbox v-model="survey.desktop_needs_compliance">Compliance</b-checkbox>
        </b-field>
        <b-field>
          <b-checkbox v-model="survey.desktop_has_suspicion">Suspicion of particular individuals</b-checkbox>
        </b-field>
        <b-field>
          <b-checkbox v-model="survey.desktop_has_dlp">Data Loss Prevention (DLP)</b-checkbox>
        </b-field>
        <b-field>
          <b-checkbox v-model="survey.desktop_needs_user_behaviour">User Behaviour Analysis</b-checkbox>
        </b-field>
        <b-field>
          <b-checkbox v-model="survey.desktop_needs_insider_threat">Insider Threat / Anti-Corruption</b-checkbox>
        </b-field>
        <b-field>
          <b-checkbox v-model="survey.desktop_needs_sensitive_data_access">Safe guarding <em>very</em> sensitive data</b-checkbox>
        </b-field>
        <b-field>
          <b-checkbox v-model="survey.desktop_needs_workflow_monitoring">Workflow governance</b-checkbox>
        </b-field>
        <b-field v-if="survey.use_cctv">
          <b-checkbox v-model="survey.desktop_needs_cctv_integration">Connect user actions with CCTV footage</b-checkbox>
        </b-field>
        <b-field v-if="survey.use_service_integration">
          <b-checkbox v-model="survey.desktop_needs_service_integration">Connect user actions with Service event logs</b-checkbox>
        </b-field>
      </section>

      <section class="section pt-2">
        <b-field message="ViPro currently only supports Microsoft Windows">
          <b-checkbox v-model="survey.confirm_windows" size="is-large">
            Please confirm all terminals are running Microsoft Windows
          </b-checkbox>
        </b-field>
      </section>

      <section class="section pt-2">
        <b-field>
          <b-checkbox v-model="survey.confirm_windows_updated" size="is-large">
            Microsoft Windows is kept up-dated with latest security patches &amp; service packs?
          </b-checkbox>
        </b-field>
        <b-field>
          <b-checkbox v-model="survey.desktop_some_32bit" size="is-large">
            There are still some 32-bit machines in this environment
          </b-checkbox>
        </b-field>
      </section>

      <section class="section pt-2">
        <h2 class="subtitle">Windows versions</h2>
        <b-field>
          <b-checkbox v-model="survey.desktop_has_winxp">XP (Service Pack 2+)</b-checkbox>
        </b-field>
        <b-field>
          <b-checkbox v-model="survey.desktop_has_vista">Vista</b-checkbox>
        </b-field>
        <b-field>
          <b-checkbox v-model="survey.desktop_has_win7">7</b-checkbox>
        </b-field>
        <b-field>
          <b-checkbox v-model="survey.desktop_has_win8">8</b-checkbox>
        </b-field>
        <b-field>
          <b-checkbox v-model="survey.desktop_has_win8_1">8.1</b-checkbox>
        </b-field>
        <b-field>
          <b-checkbox v-model="survey.desktop_has_win10">10</b-checkbox>
        </b-field>
      </section>

      <section class="section pt-2">
        <b-field label="Deployment strategy">
          <b-select v-model="survey.desktop_deployments_via" placeholder="Select a strategy">
            <option value="sccm">SCCM</option>
            <option value="manual">Manual Installation (via MSI)</option>
            <option value="other">Third-party automated deployment service</option>
          </b-select>
        </b-field>
        <b-field v-if="survey.desktop_deployments_via === 'other'">
            <b-input type="textarea"
                minlength="10"
                maxlength="1000"
                v-model="survey.desktop_other_deployment_service_explained"
                placeholder="Name the deployment process available and confirm it supports MSI packages and MSI Properties...">
            </b-input>
        </b-field>
      </section>

      <section class="section pt-2">
        <div v-if="survey.desktop_hardened_via && survey.desktop_hardened_via !== 'none'" class="notification is-warning">
          Some hardening scripts disable OS services required by ViPro, which may
          result in some loss of functionality or incompatibility. It is important
          to understand exactly what has been hardened and how to determine if this
          can hinder ViPro's ability to monitor the user.
        </div>
        <b-field label="Security hardening">
          <b-select v-model="survey.desktop_hardened_via" placeholder="Select a method">
            <option value="none">No hardening is applied to machines</option>
            <option value="cisco">Cisco hardening script</option>
            <option value="ms">Microsoft hardening script</option>
            <option value="other">Third-party or custom hardening</option>
          </b-select>
        </b-field>
        <b-field v-if="survey.desktop_hardened_via !== 'none'">
            <b-input type="textarea"
                minlength="10"
                maxlength="1000"
                v-model="survey.desktop_other_hardening_explained"
                placeholder="Please explain what restrictions are in place...">
            </b-input>
        </b-field>
      </section>

      <section class="section pt-2">
        <div v-if="['ie', 'edge', 'mix'].includes(survey.desktop_browser_used)" class="notification is-danger">
          Vigilant strongly discourage the use of Internet Explorer and MS Edge (pre Chromium), 
          which are now unsupported, unpatched and missing critial security features to 
          keep users safe. ViPro will not monitor any web traffic from these browsers.
        </div>
        <b-field label="Web Browser">
          <b-select v-model="survey.desktop_browser_used" placeholder="Select a browser">
            <option value="ie">Microsoft Internet Explorer</option>
            <option value="edge">Microsoft Edge (legacy)</option>
            <option value="edgechromium">Microsoft Edge (Chromium/latest)</option>
            <option value="chrome">Google Chrome</option>
            <option value="firefox">Mozilla Firefox</option>
            <option value="mix">Users can use whatever browser they wish</option>
          </b-select>
        </b-field>
      </section>

    </div>
    <div class="block">
      <wizard-steps :invalid="invalid"
        @next="$emit('next')"
        @previous="$emit('previous')"
        @save="$emit('save', survey)">
        <span v-if="error" class="has-text-danger">*{{ error }}</span>
      </wizard-steps>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['next', 'previous'],
  props: {
    survey: Object,
    lastDirectionWasForwards: Boolean
  },
  mounted () {
    // only needed if need desktop component license
    if (this.survey.use_desktop !== true) {
      this.$emit(this.lastDirectionWasForwards ? 'next' : 'previous')
      return
    }
  },
  computed: {
    versions () {
      return [
        this.survey.desktop_has_winxp,
        this.survey.desktop_has_vista,
        this.survey.desktop_has_win7,
        this.survey.desktop_has_win8,
        this.survey.desktop_has_win8_1,
        this.survey.desktop_has_win10,
      ].filter(a => a === true).length
    },
    error () {
      if (!this.survey.num_of_desktop_users) return 'number of desktop users needed'
      if (!this.survey.confirm_windows) return 'confirm this is a Windows-only requirement'
      if (this.versions === 0) return 'which versions of Windows need supporting?'
      if (!this.survey.desktop_deployments_via) return 'confirm how ViPro will be installed'
      if ((this.survey.desktop_other_deployment_service_explained || '').length < 10 && this.survey.desktop_deployments_via === 'other') return 'describe the determined deployment strategy'
      if (!this.survey.desktop_hardened_via) return 'are the operating systems hardened?'
      if ((this.survey.desktop_other_hardening_explained || '').length < 10 && this.survey.desktop_hardened_via !== 'none') return 'explain the hardening restrictions present'
      if (!this.survey.desktop_browser_used) return 'which browsers are used?'
      return null
    },
    invalid () {
      return this.error !== null
    }
  }
}
</script>