<template>
  <div class="service-integration">

    <section class="section pt-2">
      <b-field :label="`Service ${index+1}'s Name`" message="ideally specify the manufacturer name, product name and major version">
        <b-input v-model="integration.name"/>
      </b-field>
      <b-field label="Point of contact" message="name of the service owner / technical contact for this integration (customer side)">
        <b-input v-model="integration.contact"/>
      </b-field>
      <b-field label="Integration Type">
        <b-select v-model="integration.method" placeholder="Select a type">
          <option value="sdk">SDK can be provided</option>
          <option value="standard">Standard/Open API (publically available sdk)</option>
          <option value="logs">Parse Logs</option>
          <option value="db">Connect to underlying database</option>
        </b-select>
      </b-field>
      <b-field v-if="integration.method === 'db'" label="Database Technology">
        <b-select v-model="integration.db_technology" placeholder="Select a platform">
          <option value="tsql">Microsoft SQL Server</option>
          <option value="postgres">PostgreSQL</option>
          <option value="mysql">MySQL</option>
          <option value="mariadb">MariaDB</option>
          <option value="azuresql">Azure SQL Database</option>
          <option value="bigquery">Google BigQuery</option>
          <option value="gcpdatatable">Google Datatable</option>
          <option value="firestore">Firebase Firestore</option>
          <option value="spanner">Google Spanner</option>
          <option value="awsdynamodb">Amazon DynamoDB</option>
          <option value="awsredshift">Amazon Redshift</option>
          <option value="sqlite">SQLite</option>
          <option value="elastic">Elasticsearch</option>
          <option value="mongo">MongoDB</option>
          <option value="oracle">Oracle</option>
        </b-select>
      </b-field>
      <b-field label="Purpose and Data Fields">
        <b-input type="textarea"
          minlength="10"
          maxlength="1000"
          v-model="integration.purpose_and_data"
          placeholder="Describe the purpose of the integration and what data must be extracted from it...">
        </b-input>
      </b-field>
    </section>

    <hr/>
  </div>
</template>

<script>
export default {
  emits: ['input'],
  props: {
    survey: Object,
    integration: Object,
    index: Number
  }
}
</script>