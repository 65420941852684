<template>
  <div class="desktop">
    <h1 class="title">CCTV Requirement</h1>
    <h2 class="subtitle">Provide details about the NVRs/DVRs ViPro will monitor</h2>
    <div class="block container is-max-desktop">

      <section class="section pt-6">
        <b-field label="How many NVRs are there?">
          <b-numberinput v-model="survey.num_of_nvrs" placeholder="1" :min="1"/>
        </b-field>
        <b-field label="How many individual cameras are there roughly?">
          <b-numberinput v-model="survey.num_of_cameras" placeholder="1" :min="(survey.num_of_nvrs || 1)"/>
        </b-field>
      </section>

      <section class="section pt-6">
        <b-field label="Lead-in time around an event (in seconds)">
          <b-numberinput v-model="survey.dvr_lead_in" placeholder="10" :min="10" :max="150"/>
        </b-field>
        <b-field label="Lead-out time around an event (in seconds)">
          <b-numberinput v-model="survey.dvr_lead_out" placeholder="10" :min="10" :max="150"/>
        </b-field>
      </section>

      <section class="section pt-2">
        <div v-if="survey.some_dvrs_are_analogue" class="notification is-warning">
          If analogue CCTV/DVRs need monitoring, some hardware will need to be purchased
          to make these streams available to ViPro digitally.
        </div>
        <b-field>
          <b-checkbox v-model="survey.some_dvrs_are_analogue" size="is-large">
            Are any of the DVRs needing monitoring analogue?
          </b-checkbox>
        </b-field>
        <b-field>
          <b-checkbox v-model="survey.all_dvrs_available_from_inside_network" size="is-large">
            All NVRs are available from the internal network? (no WAN/Internet required)
          </b-checkbox>
        </b-field>
      </section>
    
    </div>
    <div class="block">
      <wizard-steps :invalid="invalid"
        @next="$emit('next')"
        @previous="$emit('previous')"
        @save="$emit('save', survey)">
        <span v-if="error" class="has-text-danger">*{{ error }}</span>
      </wizard-steps>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['next', 'previous'],
  props: {
    survey: Object,
    lastDirectionWasForwards: Boolean
  },
  mounted () {
    // only needed if need mobile component license
    if (this.survey.use_cctv !== true) {
      this.$emit(this.lastDirectionWasForwards ? 'next' : 'previous')
      return
    }
  },
  computed: {
    error () {
      if (!(this.survey.num_of_nvrs > 0)) return 'number of NVRs is required'
      if (this.survey.num_of_cameras < this.survey.num_of_nvrs) return 'number of cameras must be greater or equal to number of NVRs'
      if (!this.survey.all_dvrs_available_from_inside_network) return 'DVR streaming over WAN/Internet is not supported due to the bandwidth and latency overheads'
      return null
    },
    invalid () {
      return this.error !== null
    }
  }
}
</script>