<template>
  <div class="basics">
    <vapps-heading title="Readiness Survey" subtitle="New Project Survey &amp; Preparations"/>
    
    <div class="block container is-max-desktop">

      <section class="section pt-2">
      <b-field label="Project Name">
        <b-input v-model="survey.name"></b-input>
      </b-field>
      </section>

      <section class="section pt-6">
        <b-field label="Retention period in months">
          <b-numberinput v-model="survey.retention_in_months" placeholder="1" :min="1" :max="90"/>
        </b-field>
      </section>

      <section class="section pt-2">
        <div v-if="survey.hosting_strategy === 'hybrid'" class="notification is-warning">
          A conversation will need to be had with the customer to identify exactly
          what data can be cloud-hosted and which must remain on-premise.
        </div>
        <b-field label="Hosting strategy">
          <b-select v-model="survey.hosting_strategy" placeholder="Select a strategy">
            <option value="cloud">ViPro SaaS (Cloud)</option>
            <option value="onprem">On-Premise</option>
            <option value="hybrid">Hybrid</option>
          </b-select>
        </b-field>
        <b-field v-if="survey.hosting_strategy === 'onprem'" label="Infrastructure">
          <b-select v-model="survey.onprem_hosting_infrastructure" placeholder="Select a platform">
            <option value="bare_metal">Physical Servers (bare metal)</option>
            <option value="vms">Virtual Machines</option>
          </b-select>
        </b-field>
        <div v-if="survey.hosting_strategy === 'onprem' && survey.onprem_hosting_infrastructure === 'vms'">
          <div v-if="survey.onprem_vm_storage_type === 'pool'" class="notification is-warning">
            Shared/Pooled disks can lead to performance problems when the disks are in high demand
            by the other services. It is our preference to run on dedicated hardware, but if the customer
            is willing to take the reponsibility for the performance of the disk array, then this
            is acceptable.
          </div>
          <b-field label="VM Storage Type">
            <b-select v-model="survey.onprem_vm_storage_type" placeholder="Select a type">
              <option value="pool">Shared/Pooled Disks</option>
              <option value="dedicated">Dedicated disks</option>
            </b-select>
          </b-field>
        </div>
        <b-field v-if="survey.hosting_strategy !== 'onprem' && survey.hosting_strategy != null" label="Cloud region preference">
          <b-select v-model="survey.hosting_cloud_region" placeholder="Select a preferred region">
            <optgroup label="Americas">
              <option value="us-west1">Oregon</option>
              <option value="us-west2">Los Angeles</option>
              <option value="us-west3">Salt Lake City</option>
              <option value="us-west4">Las Vegas</option>
              <option value="us-central1">Iowa</option>
              <option value="us-east1">South Carolina</option>
              <option value="us-east4">N. Virginia</option>
              <option value="northamerica-northeast1">Montréal</option>
              <option value="southamerica-east1">São Paulo</option>
            </optgroup>
            <optgroup label="Europe">
              <option value="europe-west2">London</option>
              <option value="europe-west1">Belgium</option>
              <option value="europe-west4">Netherlands</option>
              <option value="europe-west6">Zurich</option>
              <option value="europe-west3">Frankfurt</option>
              <option value="europe-north1">Finland</option>
              <option value="europe-central2">Warsaw</option>
            </optgroup>
            <optgroup label="Asia Pacific">
              <option value="asia-south1">Mumbai</option>
              <option value="asia-southeast1">Singapore</option>
              <option value="asia-southeast2">Jakarta</option>
              <option value="asia-east2">Hong Kong</option>
              <option value="asia-east1">Taiwan</option>
              <option value="asia-northeast1">Tokyo</option>
              <option value="asia-northeast2">Osaka</option>
              <option value="asia-southeast1">Sydney</option>
              <option value="asia-northeast3">Seoul</option>
            </optgroup>
          </b-select>
        </b-field>
      </section>

      <section class="section pt-2">
      <b-field>
        <b-checkbox v-model="survey.is_covert" size="is-large">
          Does this project <strong>have</strong> to be <em>covert</em>?
        </b-checkbox>
      </b-field>
      </section>

    </div>
    <div class="block">
      <wizard-steps disable-previous :invalid="invalid" @next="$emit('next')" @save="$emit('save', survey)">
        <template>
        <span v-if="error" class="has-text-danger">*{{ error }}</span>
        </template>
        <template v-slot:beforeNext>
          <b-button v-if="!is_edit" type="is-primary" @click="loadExisting">Load Existing</b-button>
        </template>
      </wizard-steps>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['next', 'load', 'save'],
  props: {
    survey: Object
  },
  computed: {
    is_edit () {
      return this.$route.params.id != null
    },
    error () {
      if ((this.survey.name || '').length < 2) return 'project needs a name'
      if (!(this.survey.retention_in_months > 0)) return 'retention period needed'
      if (!this.survey.hosting_strategy) return 'choose a hosting strategy'
      if (this.survey.hosting_strategy !== 'onprem' && !this.survey.hosting_cloud_region) return 'choose a preferred cloud region'
      if (this.survey.hosting_strategy === 'onprem' && !this.survey.onprem_hosting_infrastructure) return 'choose an on-premise platform'
      if (this.survey.hosting_strategy === 'onprem' && this.survey.onprem_hosting_infrastructure === 'vms' && !this.survey.onprem_vm_storage_type) return 'choose a VM storage type'
      return null
    },
    invalid () {
      return this.error !== null
    }
  },
  methods: {
    loadExisting () {
      this.$buefy.dialog.prompt({
        message: `Paste in your configuration`,
        inputAttrs: {
            placeholder: '{...',
            maxlength: 100000
        },
        trapFocus: true,
        onConfirm: (value) => {
          this.$emit('load', JSON.parse(value))
        }
      })
    }
  }
}
</script>