<template>
  <ServerSpecs
    role="Frontend"
    />
</template>

<script>
import ServerSpecs from './ServerSpecs.vue'

export default {
  components: { ServerSpecs }
}
</script>