<template>
  <div class="desktop">
    <h1 class="title">Service Integrations Requirement</h1>
    <h2 class="subtitle">Provide details about the services ViPro will integrate with</h2>
    <div class="block container is-max-desktop">

      <section class="section pt-6">
        <b-field label="How many different Services need integrating with ViPro?">
          <b-numberinput v-model="survey.num_of_service_integrations" @input="resetCount" placeholder="1" :min="1"/>
        </b-field>
      </section>

      <div class="notification">
        Integration will require the arrangement of technical design/architecture meetings
        with the Service Owners managing these services on behalf of the customer.
        In the meantime, please provide as much information about the required integrations.
      </div>

      <div class="item" v-for="(integration, i) in survey.integrations" :key="i">
        <service-integration
            :survey="survey"
            :integration="integration"
            :index="i"
            />
      </div>
    
    </div>
    <div class="block">
      <wizard-steps :invalid="invalid"
        @next="$emit('next')"
        @previous="$emit('previous')"
        @save="$emit('save', survey)">
        <span v-if="error" class="has-text-danger">*{{ error }}</span>
      </wizard-steps>
    </div>
  </div>
</template>

<script>
import ServiceIntegration from './ServiceIntegration.vue'

export default {
  components: { ServiceIntegration },
  emits: ['next', 'previous'],
  props: {
    survey: Object,
    lastDirectionWasForwards: Boolean
  },
  mounted () {
    // only needed if need mobile component license
    if (this.survey.use_service_integration !== true) {
      this.$emit(this.lastDirectionWasForwards ? 'next' : 'previous')
      return
    }
  },
  computed: {
    error () {
      if (!(this.survey.num_of_service_integrations > 0)) return 'number of services to integrate is required'
      return null
    },
    invalid () {
      return this.error !== null
    }
  },
  methods: {
    resetCount () {
      this.survey.integrations = this.survey.integrations || []
      for (let i=0; i < this.survey.num_of_service_integrations - this.survey.integrations.length; i++) {
          this.survey.integrations.push({ })
      }
      for (let i=0; i < this.survey.integrations.length - this.survey.num_of_service_integrations; i++) {
        this.survey.integrations.pop()
      }
    }
  }
}
</script>