import Basics from './Basics.vue'
import Components from './Components.vue'
import Mobile from './Mobile.vue'
import Desktop from './Desktop.vue'
import CCTV from './CCTV.vue'
import ServiceIntegrations from './ServiceIntegrations.vue'
import Done from './Done.vue'

export default [
  Basics,
  Components,
  Mobile,
  Desktop,
  CCTV,
  ServiceIntegrations,
  Done,
]