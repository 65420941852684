<template>
  <div class="components is-unselectable">
    <h1 class="title">Component Selection</h1>
    <h2 class="subtitle">Which ViPro Components are required?</h2>

    <div class="columns block has-text-centered mt-6">
      <div class="column p-6">
        <Icon title="Mobile" icon="mobile-alt" v-model="survey.use_mobile"/>
      </div>
      <div class="column p-6">
        <Icon title="Desktop" icon="desktop" v-model="survey.use_desktop"/>
      </div>
      <div class="column p-6">
        <Icon title="CCTV" icon="video" v-model="survey.use_cctv"/>
      </div>
      <div class="column p-6">
        <Icon title="Service Integrations" icon="plug" v-model="survey.use_service_integration"/>
      </div>
    </div>
    <div class="block">
      <wizard-steps :invalid="invalid" @next="$emit('next')" @previous="$emit('previous')" @save="$emit('save', survey)">
        <span v-if="selected_count === 0">Please select at least 1 ViPro component.</span>
        <span v-else>{{ selected_count }} ViPro components required.</span>
      </wizard-steps>
    </div>
  </div>
</template>

<script>
import Icon from '../reusable/IconWithTick.vue'

export default {
  emits: ['next', 'previous'],
  props: {
    survey: Object
  },
  components: { Icon },
  computed: {
    selected_count () {
      return [
        this.survey.use_mobile,
        this.survey.use_desktop,
        this.survey.use_service_integration,
        this.survey.use_cctv
      ].filter(b => b === true).length
    },
    invalid () {
      return this.selected_count == 0
    }
  }
}
</script>