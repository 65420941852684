<template>
  <div class="is-clickable" @click="$emit('input', !(value === true))">
    <font-awesome-layers class="fa-5x mb-3">
      <font-awesome-icon :icon="icon" class="has-text-link"/>
      <font-awesome-icon v-if="value" icon="check-circle" class="mb-3 has-text-success" transform="down-9 right-7 shrink-9"/>
    </font-awesome-layers>
    <h2 v-if="title" class="title">{{ title }}</h2>
  </div>
</template>

<script>
export default {
  emits: ['input'],
  props: {
    title: String,
    icon: [Array, String],
    value: Boolean,
  }
}
</script>