<template>
  <div class="wizard">
    <component :is="stepComponent" :survey="survey" 
      @next="on_next" 
      @previous="on_previous"
      @save="on_save"
      @load="e => survey = e"
      :lastDirectionWasForwards="lastDirectionWasForwards"/>
  </div>
</template>

<script>
import steps from './steps'

export default {
  emits: ['save'],
  props: {
    existingSurvey: Object
  },
  data () {
    return {
      step: 0,
      lastDirectionWasForwards: true,
      survey: this.existingSurvey || {}
    }
  },
  watch: {
    existingSurvey (newSurvey) {
      this.survey = newSurvey
    }
  },
  computed: {
    stepComponent () {
      return steps[this.step]
    }
  },
  methods: {
    on_next () {
      this.on_save()
      this.lastDirectionWasForwards = true
      this.step += 1
    },
    on_save () {
      this.$emit('save', this.survey)
    },
    on_previous () {
      this.on_save()
      this.lastDirectionWasForwards = false
      this.step -= 1
    }
  }
}
</script>